import "./Result.css";

// hooks
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// react-joyride
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import ResultDisplay from "src/components/ResultDisplay";


function Result() {
    const location = useLocation();
    const { responseData } = location.state || {};
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [tourSteps] = useState([
        {
            target: '.button-popup',
            content: isMobile
                ? 'Clique para abrir o popup e use os dedos para dar zoom na imagem ou visualize em tela cheia, você pode interagir com o gráfico também!'
                : 'Clique para abrir o popup e use o scroll para dar zoom na imagem ou visualize em tela cheia, você pode interagir com o gráfico também!',
            disableBeacon: true,
        },
    ]);

    const [runTour, setRunTour] = useState<boolean>(() => {
        return sessionStorage.getItem('hasSeenTour') !== 'true';
    });
    const navigate = useNavigate();

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;

        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
            setRunTour(false);
            sessionStorage.setItem('hasSeenTour', 'true');
        }
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (!responseData) {
            navigate('/');
        }
    }, [responseData, navigate]);

    return (
        <div className="container-result-details">
            <h1>Parabéns por realizar sua simulação!</h1>
            <h2>Aqui está seu resultado:</h2>
            <Joyride
                steps={tourSteps}
                run={runTour}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        zIndex: 10000,
                        textColor: '#fff',
                        backgroundColor: '#193570',
                        arrowColor: '#193570',
                    },
                    buttonNext: {
                        color: '#193570',
                        backgroundColor: '#fff'
                    }
                }}
            />
            <ResultDisplay responseData={responseData} />
            <button onClick={() => navigate('/')}>Faça Outra Simulação!</button>
        </div>
    );
}

export default Result;
import ReactLoading from 'react-loading';
import './Loading.css';
import { useState, useEffect } from 'react';

const Loading = () => {

  const [currentMessage, setCurrentMessage] = useState<number>(0);
  const [dots, setDots] = useState<string>("");

  const messages = [
    'Buscando grupo',
    'Reunindo informações',
    'Preparando exibição'
  ]

  useEffect(() => {

    const dotsInterval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ""))
    }, 500);

    const messageInterval = setInterval(() => {
      setCurrentMessage((prevIndex) => (prevIndex + 1) % messages.length);
    }, 7000);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(messageInterval);
    };


  }, [messages.length])

  return (
    <div className="loading-screen">
      <ReactLoading type="spin" color="#193570" height={100} width={100} />
      <p>
        {messages[currentMessage]}{dots}
      </p>
    </div>
  );
};

export default Loading;

import "./Footer.css"

function Footer() {
    return (
        <div className="footer">
            <h1>Todos os direitos reservados Grupo AZConeX &copy; 2024</h1>
        </div>
    )
}

export default Footer

import './App.css';

//hooks
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//pages
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Result from './pages/result/Result';
import Admin from './pages/admin/Admin';

//components
import {Layout} from './components/Layout';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout><Home/></Layout>}/>
          <Route path='/login' element={<Login />}/>
          <Route path='/result' element={<Layout><Result /></Layout>}/>
          <Route path='/admin' element={<Layout><Admin /></Layout>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

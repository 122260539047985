import "./Login.css";

//hooks
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";

//components
import { Message } from "../../components/message/Message";

//shadcn
import { Input } from "src/components/ui/input";

interface User {
    id: string;
    nome: string;
    email: string;
    senha: string;
    role: string;
}

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<boolean>(false)

    const navigate = useNavigate();

    const users = JSON.parse(process.env.REACT_APP_USERS || '[]');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const user: User | undefined = users.find((u: User) => u.email === email && u.senha === password);

        if (user) {
            sessionStorage.setItem('email', email);
            sessionStorage.setItem('name', user.nome);
            sessionStorage.setItem('role', user.role)
            if (user.role === 'Admin' || user.role === 'Dev') {
                navigate('/admin')
            } else {
                navigate('/');
            }
        } else {
            console.error('Credenciais inválidas');
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 3000)
        }
    };

    useEffect(() => {
        setEmail("");
        setPassword("");
    }, []);

    return (
        <div className="login">
            <div className="form-container">
                <div className="form">
                    <img src="https://grupoazconex.com.br/wp-content/uploads/2024/07/cropped-AZCONEX-LOGO-2048x540.png" alt="azconex_logo" />
                    <h1>Simulador</h1>
                    <form onSubmit={handleSubmit}>
                        <Input
                            type="email"
                            className="input"
                            placeholder="Insira seu e-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <Input
                            type="password"
                            className="input"
                            placeholder="Insira sua senha"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <button type="submit" className="button">Entrar</button>
                        {error && <Message msg='Credenciais Inválidas' type='error' />}
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;

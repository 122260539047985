import './Admin.css';
import { useNavigate } from 'react-router-dom';

// Definição de interface para tipagem dos usuários
interface User {
    id: number;
    nome: string;
    role: string;
    email: string;
}

function Admin() {
    const usersString = process.env.REACT_APP_USERS;
    const users: User[] = usersString ? JSON.parse(usersString) : [];

    const email = sessionStorage.getItem('email');
    const role = sessionStorage.getItem('role');
    const navigate = useNavigate();

    if(role !== 'Admin' || 'Dev'){
        navigate("/")
    }

    return (
        <div className='admin-container'>
            {users.length > 0 ? (
                <>
                    {users.map((user: User) => (
                        user.email === email ? (
                            <div className='divisor' key={user.id}>
                                <div className='admin-user'>
                                    <h1>Seu Usuário:</h1>
                                    <h2>{user.nome}</h2>
                                    <h3>{user.role}</h3>
                                    <h3>{user.email}</h3>
                                </div>
                                <div className='change-page'>
                                    <h2>Se deseja realizar uma simulação:</h2>
                                    <button onClick={() => navigate('/')}>Clique Aqui!</button>
                                </div>
                            </div>
                        ) : null
                    ))}

                    <div>
                        <h1>Outros usuários cadastrados:</h1>
                    </div>
                    <div className="users-grid">
                        {users.map((user: User) => (
                            user.email !== email && (
                                <div key={user.id} className='regular-user'>
                                    <h2>{user.nome}</h2>
                                    <h3>{user.role}</h3>
                                    <h3>{user.email}</h3>
                                </div>
                            )
                        ))}
                    </div>
                </>
            ) : (
                <p>Nenhum usuário encontrado.</p>
            )}
        </div>
    );
}

export default Admin;

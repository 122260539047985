import "../pages/result/Result.css"

//react
import { useState, useEffect } from "react";

//zoom
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//icons
import { BsFillBuildingsFill } from "react-icons/bs";
import { FaBoxOpen, FaMotorcycle } from "react-icons/fa6";
import { FaCar, FaTractor } from "react-icons/fa";

// reactjs-popup
import Popup from "reactjs-popup";

//components
import Graph from "./Graph";


interface Props {
    responseData: any;
}

const ResultDisplay: React.FC<Props> = ({ responseData }) => {

    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const resultIcon: Record<string, JSX.Element> = {
        'TC': <FaTractor />,
        'IM': <BsFillBuildingsFill />,
        'AI': <FaCar />,
        'AU': <FaCar />,
        'MO': <FaMotorcycle />,
        'EE': <FaBoxOpen />
    };

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let bids_json: any = null;
    let winners_json: any = null;
    let bids_message: any = null;

    if (responseData.bids_json) {
        bids_json = JSON.parse(responseData.bids_json);
        winners_json = JSON.parse(responseData.winners_json)
    }

    if (responseData.bids_message) {
        bids_message = JSON.parse(responseData.bids_message);
    }

    return (
        <div className="results-grid">
            {responseData.best_groups_json && Array.isArray(responseData.best_groups_json) && responseData.best_groups_json.length > 0 ? (
                responseData.best_groups_json.map((group: any) => {
                    let valorTotal: any;
                    if (group.Cotas)
                        valorTotal = group.Valor_Bem * group.Cotas
                    return (
                        <div className="group-wrapper" key={group.id}>
                            <div className="result-details">
                                <div className="icon">{resultIcon[group.Segmento]}</div>
                                {group.Grupo === 0 ? (
                                    <h1>Grupo em Formação {group.Grupo}</h1>
                                ) : (
                                    <h1>Grupo {group.Grupo}</h1>
                                )}
                                <h2>{responseData.Segmento}</h2>
                                <h3>Bem: {group.Bem}</h3>
                                {group.Cotas && <h3>Valor Total: R$ {valorTotal}</h3>}
                                <h3>Valor por Cota: R$ {group.Valor_Bem}</h3>
                                {group.Cotas ? <h3>Parcela Total: R$ {group.Total_Parcelas}</h3> : <h3>Parcela Total: R$ {group.Valor_Parcela}</h3>}
                                {group.Cotas && <h3>Qtde de Cotas: {group.Cotas}</h3>}
                                <h3>Taxa de Admin Total: {group.Percentual_Taxa_Adm}%</h3>
                                <h3>Taxa de Admin Mensal: {group.Percentual_Taxa_Adm_Mensal}%</h3>
                                <h3>Prazo: {group.Prazo} meses</h3>
                                <h3>Vagas disponíveis: {group.Vagas}</h3>
                                <Popup
                                    position={'bottom center'}
                                    className="popup-content"
                                    trigger={<button className="button-popup">Mais Detalhes</button>}
                                    on={['click']}
                                >
                                    {responseData.months_json && responseData.bids_json && responseData.winners_json ? (
                                        <div className={fullScreen ? 'full-screen' : ''}>
                                            {responseData.bids_message && <h3>{bids_message.Message}</h3>}
                                            <div>
                                                <TransformWrapper>
                                                    <TransformComponent>
                                                        <div className="graph">
                                                            <button className="fullscreen-button" onClick={toggleFullScreen}>
                                                                {fullScreen ? 'Sair da Tela Cheia' : 'Tela Cheia'}
                                                            </button>
                                                            <Graph
                                                                months={JSON.parse(responseData.months_json)}
                                                                bid={bids_json[group.Grupo]}
                                                                winners={winners_json[group.Grupo][0]}
                                                                layout={isMobile}
                                                                group={group.Grupo}
                                                            />
                                                        </div>
                                                    </TransformComponent>
                                                </TransformWrapper>
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Esse grupo não possui lances ainda.</p>
                                    )}
                                </Popup>
                            </div>
                        </div>
                    );
                })
            ) : (
                <p>Não foram encontrados grupos com essas especificações.</p>
            )}
        </div>
    )
}

export default ResultDisplay
import React from "react"
import "./Message.css"

type props = {
    msg: string,
    type: string
}

export const Message: React.FC<props> = ({ msg, type }) => {

    return (
        <div className={`message ${type}`}>
            <p>{msg}</p>
        </div>
    )
}

import { Line } from "react-chartjs-2";
import "chart.js/auto";

interface Props {
  months: string[];
  bid: number[];
  winners: number[];
  layout: boolean;
  group: string;
}

const Graph = ({ months, bid, winners, layout, group }: Props) => {
  const dataSet = {
    labels: months,
    datasets: [
      {
        label: `Histórico de Lances e Contemplados Grupo ${group}`,
        backgroundColor: "#193579",
        borderColor: "#193579",
        pointBorderColor: "#193579",
        pointBackgroundColor: "#193579",
        pointBorderWidth: 1,
        pointRadius: 5,
        data: bid,
        fill: false,
        tension: 0.2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const bidValue = context.raw;
            const winnersValue = winners[context.dataIndex] ?? 'Sem dados disponíveis';
            return `Lance: ${bidValue}%, Contemplados: ${winnersValue}`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    layout: {
      padding: 10,
    },
    maintainAspectRatio: false,
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px", minWidth: layout ? "350px" : "600px", height: '200px  ' }}>
      <Line data={dataSet} options={options} />
    </div>
  );
};

export default Graph;
